
.addlider-title {
    font-weight: bold;
    font-size: 2.5rem;
    cursor: pointer;
    padding: 5px;
}

.addliderform-wrap {
    width: 40%;
    height: 95%;
}

.addlider-container {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
}

.addliderform-overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    cursor: pointer;
}

.addliderform-x {
    width: 20px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 100;
}

@media only screen and (max-width: 992px) {
    .addliderform-wrap {
        width: 50%;
    }
}

@media only screen and (max-width: 768px) {
    .addliderform-wrap {
        width: 60%;
    }
}

@media only screen and (max-width: 576px) {
    .addliderform-wrap {
        width: 90%;
    }
}