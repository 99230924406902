.rag-green {
    background-color: green;
}

.rag-red {
    background-color: red;
}

.rag-orange {
    background-color: orange;
}

.rag-white {
    background-color: white;
}

.konsultantkiGrid-buttons-container {
}

.konsultantkiGrid-buttons-container button {
    margin-top: 10px;
    margin-left: 10px;
}

.konsultantkiGrid-info-container {
    display: flex;
    flex-direction: row;
    justify-content: right;

}

.konsultantkiGrid-info-item {
    padding: 5px 10px;
    font-size: 1.1em;
    font-weight: bold;
}