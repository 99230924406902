.notificationsPanelItem-container {
    border: 1px solid;
    padding: 10px;
    cursor: default;
}

.notificationsPanelItem-title {
    font-weight: bold;
}

.notificationsPanelItem-item {
    margin-top: 5px;
}

.notificationsPanelItem-dateTime {}

.notificationsPanelItem-description {}

.notificationsPanelItem-type {}