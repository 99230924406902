.screenShootsTab-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.screenShootsTab-arrow {
  font-size: 100pt;
  margin: 0px 10px 50px 10px;
  cursor: pointer;
  user-select: none;
}

.screenShootsTab-caption {
  text-align: center;
  font-size: 1.2em;
  margin: 10px;
}

.screenShootsTab-number {
  font-weight: bold;
}

.screenShootsTab-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.screenShootsTab-image-img {
  height: 500px;
  width: auto;
  cursor: pointer;
}


/* media queries */
@media screen and (min-width: 992px) and (max-width: 1199px) {

}

@media screen and (min-width: 768px) and (max-width: 991px) {

}

@media screen and (min-width: 577px) and (max-width: 767px) {
  .screenShootsTab-image-img {
    height: 400px;
  }
}

@media screen and (max-width: 576px) {
  .screenShootsTab-arrow {
    font-size: 70pt;
    margin: 0px 0px 50px 0px;
  }

  .screenShootsTab-image-img {
    height: 400px;
  }
}