.page-roles-title {
    text-align: center;
    padding: 20px;
}

.page-roles-title-hr{
    margin: auto;
    width: 50%;
}

.role-container{
    display: flex;
    justify-content: center;
}

.role-container-panel{
    width: 50%;
}

.role, .role-add {
    padding: 10px;
}

.role-add-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.role-add-input {
    margin-right: 15px;
    font-size: 2rem;
}

.role-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.role-title {
    font-weight: bold;
    font-size: 2.5rem;
    cursor: pointer;
    padding: 5px;
}

.accessrighttype-title {
    font-weight: bold;
    cursor: pointer;
    font-size: 2rem;
}

.accessrighttype {
    padding: 10px 25px;
}

.accessright {
    border-collapse: separate;
    border-spacing: 0 7px;
    padding: 10px 15px;
}

.accessright-title {
    font-size: 1.5rem;
    padding-right: 20px;
}

.accessright-row td:first-child {
    width: 300px;
}

.accessright-row td:nth-child(2) {
    min-width: 80px;
}

.accessright-checkbox {
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .role-container-panel {
        width: 75%;
    }

    .accessright-row td:nth-child(2) {
        min-width: 80px;
    }
}

@media only screen and (max-width: 576px) {
    .page-roles-title-hr{
        margin: auto;
        width: 90%;
    }

    .role-container-panel {
        width: 100%;
    }

    .accessright-row td:nth-child(2) {
        min-width: 60px;
    }

    .accessrighttype {
        padding: 10px 5px;
    }

    .accessright {
        border-collapse: separate;
        border-spacing: 0 7px;
        padding: 10px 0px;
    }
}