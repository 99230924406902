.settings-userspage-container {
    height: calc(100vh - 200px);
    box-sizing: border-box;
}

.settings-userspage-bar{
    margin: 20px 0px;
}

.settings-userspage-bar *{
    margin-left: 2rem;
    margin-bottom: 1rem;
}

.edituserform-wrapform {
    width: 40%;
}

/* edituserform */
.edituserform-overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    cursor: pointer;
}


@media only screen and (max-width: 992px) {
    .edituserform-wrapform {
        width: 50%;
    }
}

@media only screen and (max-width: 768px) {
    .edituserform-wrapform {
        width: 60%;
    }
}

@media only screen and (max-width: 576px) {
    .edituserform-wrapform {
        width: 90%;
    }
}