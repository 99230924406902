.integrationssettingspage-container
{
    padding: 10px;
}

.integrationssettingspage-section
{
    margin-top: 10px;
}

.integrationssettingspage-section-title
{
    margin-bottom: 20px;
}

.integrationssettingspage-section-item
{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.integrationssettingspage-section-item-label
{
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12pt;
}