.settingsnavbar-wrapper {
  display: flex;
  background-color: #eeeeee;
}

.settingsnavbar-nav {
  flex: 1;
  align-self: flex-start;
  padding: 1rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ddd;
  width: 100vw;
  top: 0;
}

.settingsnavbar-items {
  display: flex;
  align-items: center;
  list-style: none;
}

.settingsnavbar-item {
  padding: 0 1rem;
  cursor: pointer;
  text-align: left;
}

.settingsnavbar-arrowdown-container {
  padding: 0;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: end;
}

.settingsnavbar-arrow-img {
  height: auto;
  width: 36px;
  cursor: pointer;
}

@media only screen and (max-width: 40em) {
  .settingsnavbar-nav {
    padding: 1rem 1rem;
  }

  .settingsnavbar-items {
    text-align: left;
  }

  .settingsnavbar-item:first-child {
    padding: 0;
    margin: 0;
  }

  .settingsnavbar-items {
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
  }

  .settingsnavbar-item {
    padding: 1rem 1rem 1rem 2rem;
    text-align: left;
    width: 100%;
  }
}