.notificationsPanel-container {
    position: absolute;
    z-index: 100;
    background-color: white;
    width: 300px;
    left: -100px;
    top: 50px;
    border: 1px solid;
}

@media only screen and (max-width: 768px)  {
    .notificationsPanel-container {
        left: -180px;
    }
}

.notificationsPanel-items-container {
    max-height: 400px;
    overflow-y: auto;
}

.notificationsPanel-preview-button {
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    font-weight: bold;
    border-top: 1px solid;
    cursor: pointer;
}
