.notificationButton-contrainer {
    position: relative;
}

.notificationButton-counter {
    position: absolute;
    background-color: silver;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    top: -10px;
    right: -10px;
}