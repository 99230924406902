.modal-title {
    margin-bottom: 5px;
}

.modal-text{
    word-break: break-word;
    white-space: pre-wrap;
}

.modal-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}

.modal-buttons-container button
{
    margin-left: 20px;
}

.modal-buttons-container button:first-child
{
    margin-left: inherit;
}

.modal-x{
    width: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}