.sublidersgrid-container {
    margin-right: 10px;
    box-sizing: border-box;
    width: 100%;
}

.sublider-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* max-width: 900px; */
    border-bottom: 1px solid rgba(120, 120, 120, 0.5);
    padding: 10px 0px;
    min-height: 50px;
    box-sizing: border-box;
}

.sublider-row-item {
    margin-right: 10px;
}

.sublider-row-item-buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.sublider-row-item-buttons-container *{
    margin-bottom: 1rem;
    margin-right: 1.5rem;
}

.sublider-row-icon-container {
    padding-right: 1.5rem;
}

.settingsMainPage-buttons-container {
    padding: 10px;
    height: 15%;
}

.settingsMainPage-buttons-container button {
    margin-left: 10px;
}

.registrationRedirectionsScheduleGrid-container {
    margin-top: 40px;
}

@media only screen and (max-width: 992px) {
    .sublider-row {
        flex-direction: column;
        align-items: start;
    }

    .sublider-row-item {
        margin-right: 0;
        margin-bottom: 5px;
    }
}

@media only screen and (max-width: 768px) {
    .sublider-row {
    }
}

@media only screen and (max-width: 576px) {
    .sublider-row {
    }
}