.nav-link{
    text-decoration: none;
    color: black;
    font-weight: bolder;
}

.nav-link-active{
    text-decoration: none;
    color: rgba(0, 0, 0, 0.5);
    font-weight: bolder;
}

.nav-link:hover{
    color: rgba(0, 0, 0, 0.5);
}