.registrationredirectionsschedulegrid-container {
    padding-top: 10px;
    width: 100%;
    box-sizing: border-box;
}

.registrationredirectionsschedulegrid-buttons-container {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.registrationredirectionsschedulegrid-buttons-container * {
    margin-left: 10px;
    margin-bottom: 10px;
}