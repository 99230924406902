.konsultantkaDataTab-container {
  overflow: auto;
  height: 100%;
}

.konsultantkaDataTab-table {

}

.konsultantkaDataTab-table td {
  padding: 5px 10px;
  border: 1px solid silver;
}

@media only screen and (min-width: 768px) {
  .konsultantkaDataTab-table td:nth-child(2) {
    width: 400px;
  }
}

@media only screen and (min-width: 576px) {
  .konsultantkaDataTab-table td:nth-child(2) {
    width: 300px;
  }
}

.konsultantkaDataTab-table td:first-child {
  font-weight: bold;
}