.konsultantkiGrid {
  height: calc(100vh - 210px)
}

.panel-bar {
  height: 15px;
  width: 100%;
  background-color: silver;
  cursor: pointer;
}

@media only screen and (max-width: 576px) {
  .panel-bar {
    height: 35px;
  }
}