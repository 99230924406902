.notificationsModalContainer-container {
  position: absolute;
  right: 50px;
  bottom: 10px;
}

.notificationsModalContainer-container-mobile {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  z-index: 100;
}