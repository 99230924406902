.addregistrationredirectionsscheduleform-wrapform {
    width: 40%;
    height: 60%;
}

.addregistrationredirectionsscheduleform-overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    cursor: pointer;
}

.addregistrationredirectionsscheduleform-x {
    width: 20px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 100;
}

@media only screen and (max-width: 992px) {
    .addregistrationredirectionsscheduleform-wrapform {
        width: 50%;
    }
}

@media only screen and (max-width: 768px) {
    .addregistrationredirectionsscheduleform-wrapform {
        width: 60%;
    }
}

@media only screen and (max-width: 576px) {
    .addregistrationredirectionsscheduleform-wrapform {
        width: 90%;
    }
}