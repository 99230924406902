.mainNavbar-wrapper{
    display: flex;
    background-color: #eeeeee;
}

.mainNavbar-navbar-wrapper{
    flex: 1;
    align-self: flex-start;
    padding: 1rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    position: fixed;
    z-index: 100;
    width: 100vw;
    top: 0;
}

.mainNavbar-navbar-logo {
    border: 1px solid gray;
    padding: 0.5rem 1rem;
}

.mainNavbar-navbar-userInfo {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
}

.mainNavbar-navbar-items {
    align-items: center;
    display: flex;
    list-style: none;
}

@media only screen and (max-width: 40em) {
    .mainNavbar-navbar-items
    {
        position: fixed;
        right: 0;
        top: 0;
        height: 100%;
        flex-direction: column;
        background-color: white;
        padding: 1rem 2rem;
        transition: 0.2s ease-out;
    }
  }

.mainNavbar-navbar-item {
    padding: 0 1rem;
    cursor: pointer;
}

@media only screen and (max-width: 40em) {
    .mainNavbar-navbar-item{
        padding: 1rem 0;
    }
}

.mainNavbar-hamburgerButton-wrapper {
    height: 3rem;
    width: 3rem;
    position: relative;
    font-size: 12px;
    display: none;

    /* Remove default button styles */
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
}

@media only screen and (max-width: 40em) {
    .mainNavbar-hamburgerButton-wrapper {
        display: block;
    }
}

.mainNavbar-hamburgerButton-wrapper::after {
    content: "";
    display: block;
    position: absolute;
    height: 150%;
    width: 150%;
    top: -25%;
    left: -25%;
  }

  .mainNavbar-hamburgerButton-lines {
    top: 50%;
    margin-top: -0.125em;
    height: 2px;
    pointer-events: none;
    display: block;
    content: "";
    width: 100%;
    background-color: black;
    position: absolute;
  }

  .mainNavbar-hamburgerButton-lines::after{
    top: -0.8rem;
  }

  .mainNavbar-hamburgerButton-lines::before{
    top: 0.8rem;
  }