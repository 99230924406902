.edituserform-container{
    position: relative;
}

.edituserform-x{
    width: 20px;
    position: absolute;
    top: -20px;
    right: -20px;
    cursor: pointer;
}