.notificationsModal-container {
  padding: 10px;
  margin: 10px;
  background-color: #e5e5e5;
  border: 2px solid #adadad;
  border-radius: 0.5em;
  width: 300px;
  height: 120px;
  overflow: hidden;
  position: relative;
}

.notificationsModal-xsymbol {
  width: 16px;
  height: auto;
  right: 10px;
  top: 10px;
  position: absolute;
  cursor: pointer;
}

.notificationsModal-item {
  margin-top: 5px;
}

.notificationsModal-title {
  font-weight: bold;
  text-align: center;
  text-wrap: wrap;
}

.notificationsModal-description {
  text-wrap: wrap;
  overflow: auto;
}

@media only screen and (max-width: 768px) {
  .notificationsModal-container {
    width: 300px;
    height: auto
  }
}