.sublidersgrid-container {
  overflow: auto;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid;
}

.sublidersgrid-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* max-width: 900px; */
  border-bottom: 1px solid rgba(120, 120, 120, 0.5);
  padding: 10px 0px;
  min-height: 50px;
  box-sizing: border-box;
}

.sublidersgrid-row-item {
  margin-right: 10px;
}

.sublidersgrid-row-item-buttons-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.sublidersgrid-row-item-buttons-container *{
  margin-bottom: 1rem;
  margin-right: 1.5rem;
}

.sublidersgrid-row-icon-container {
  padding-right: 1.5rem;
}

.settingsMainPage-buttons-container {
  padding: 10px;
  height: 15%;
}

.settingsMainPage-buttons-container button {
  margin-left: 10px;
}

.registrationRedirectionsScheduleGrid-container {
  margin-top: 40px;
}

@media only screen and (max-width: 992px) {
  .sublidersgrid-row {
      flex-direction: column;
      align-items: start;
  }

  .sublidersgrid-row-item {
      margin-right: 0;
      margin-bottom: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .sublidersgrid-row {
  }
}

@media only screen and (max-width: 576px) {
  .sublider-row {
  }
}