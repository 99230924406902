.loginPage-version-container {
    margin-top: 30px;
}

.loginPage-version-container p{
    margin-top: 10px;
}

.loginPage-alert-container {
    margin-top: 10px;
}