.tabspanel-nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 20px;
  font-size: 1.5rem;
  overflow: auto;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 2px solid;
  box-sizing: border-box;
}

.tabspanel-nav-item {
  text-align: left;
  padding: 10px 30px;
  box-sizing: border-box;
  cursor: pointer;
  text-wrap: nowrap;
}

.tabspanel-nav-item:hover {
  color: rgba(0, 0, 0, 0.7);
  border-color: rgba(0, 0, 0, 0.7);
}

.tabspanel-nav-item-selected {
  font-weight: bold;
}

.tabspanel-content {
  margin: 0 20px;
  height: calc(100% - 65px);
  width: calc(100% - 40px);
  box-sizing: border-box;
}

@media only screen and (max-width: 576px) {
  .tabspanel-content {
    height: calc(100% - 75px);
  }
}