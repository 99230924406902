.konsultantkipreviewpanel-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  height: calc(100% - 30px);
  box-sizing: border-box;
}

@media only screen and (max-width: 576px) {
  .konsultantkipreviewpanel-container {
    height: calc(100% - 40px);
  }
}