.appsettingspage-container {
  padding: 2rem;
  height: 100%;
  width: 100%;
}

.appsettingspage-group-caption {
  border-bottom: 1px solid;
  width: 95%;
  padding-bottom: 1rem;
  box-sizing: border-box;
}

.appsettingspage-group {
  margin-bottom: 2rem;
}

.appsettingspage-group-fieldstable {
  border-spacing: 2rem 2rem;
  font-size: 1.5rem;
}

.appsettingspage-group-fieldstable tr {
  padding-top: 20px;
}

.appsettingspage-group-fieldstable > tr > td:first-child {
  min-width: 150px;
}

.appsettingspage-group-input {
  height: 2.2rem;
  font-size: 1.5rem;
  box-sizing: border-box;
}

.appsettingspage-group-input[type='text'] {
  width: 50rem;
  padding: 1rem 0;
}

.appsettingspage-group-input[type='checkbox'] {
  width: 2.2rem;
}

