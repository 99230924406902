.screenschootpreview-wrap {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}

.screenschootpreview-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    /* align-items: center; */
}

.screenschootpreview-img-container {
    height: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.screenschootpreview-img-arrow {
    font-size: 100pt;
    margin: 40px;
    cursor: pointer !important;
}

.screenschootpreview-img {
    height: 90%;
    width: auto;
}

.screenschootpreview-title-container {
    /* height: 30%; */
    /* margin-top: 20px; */
}

.screenschootpreview-title {
    text-align: center;
    font-size: 20pt;
    color: white;
}

.screenschootpreview-number {
    font-weight: bold;
}

.screenschootpreview-xsymbol {
    position: absolute;
    width: 36px;
    height: 36px;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

}

@media screen and (min-width: 768px) and (max-width: 991px) {

}

@media screen and (min-width: 577px) and (max-width: 767px) {
.screenschootpreview-img-container  {
    height: auto;
}

.screenschootpreview-img {
    height: 450px;
}

.screenschootpreview-img-arrow {
    font-size: 80pt;
    margin: 0px;
}

.screenschootpreview-title-container {
    margin-top: 20px;
}

}

@media screen and (max-width: 576px) {
.screenschootpreview-img-container  {
    height: auto;
}

.screenschootpreview-img {
    height: 450px;
}

.screenschootpreview-img-arrow {
    font-size: 80pt;
    margin: 0px;
}

.screenschootpreview-title-container {
    margin-top: 20px;
}
}